import React from 'coact/react'

import moment from 'coact/moment'
import numeral from 'coact/numeral'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import termDefine from './printerSetting/termDefine'
import styles from './printerSetting/styles'

const { object, number } = PropTypes

function StatementHeader ({ doc, classes }) {
  const shortName = (doc.statementType === 'sale' || doc.statementType === 'saleReturn')
    ? doc.sellerRef && doc.sellerRef.shortName
    : doc.buyerRef && doc.buyerRef.shortName
  const address = (doc.statementType === 'sale' || doc.statementType === 'saleReturn')
    ? doc.seller && doc.seller.address
    : doc.buyerRef && doc.buyerRef.address
  const phone = (doc.statementType === 'sale' || doc.statementType === 'saleReturn')
    ? doc.seller && doc.seller.phone
    : doc.buyerRef && doc.buyerRef.phone
  const fax = (doc.statementType === 'sale' || doc.statementType === 'saleReturn')
    ? doc.seller && doc.seller.FAX
    : doc.buyerRef && doc.buyerRef.FAX
  return (
    <table className={[classes.table, classes['header-region']].join(' ')}>
      <tbody>
        <tr>
          <td className={classes['header-left']}>
            <div className={classes.seller}>
              <div className={classes['seller-print-data']}>中天眼鏡-{shortName}</div>
            </div>
          </td>
          <td className={classes['header-mid']}>
            <div>
              <div className={classes['header-mid-form-type-print-data']}>
                {termDefine[doc.statementType].tableType}單
              </div>
            </div>
          </td>
          <td className={classes['header-right-top']}>
            <div>
              <div className={classes['header-right-top-key-print-data']}>{doc.key}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td className={classes['header-left']}>
            <div>
              <div className={classes['sellerAddress-print-data']}>{address}</div>
            </div>
          </td>
          <td colSpan={2} rowSpan={2} className={classes['header-right-bottom']}>
            <div>
              <div className={classes['header-right-bottom-key-print-data']}>*{doc.key}*</div>
            </div>
          </td>
        </tr>
        <tr>
          <td className={classes['header-left']}>
            <div>
              <div className={classes['sellerPhone-print-data']}>
                TEL: {phone} FAX: {fax}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

StatementHeader.propTypes = {
  classes: object.isRequired,
  doc: object.isRequired,
}

function SheetTitle ({ doc, classes, pageNum }) {
  const name = (doc.statementType === 'sale' || doc.statementType === 'saleReturn')
    ? doc.buyerRef && doc.buyerRef.name
    : doc.sellerRef && doc.sellerRef.name
  const phone = (doc.statementType === 'sale' || doc.statementType === 'saleReturn')
    ? doc.buyerRef && doc.buyerRef.phone
    : doc.sellerRef && doc.sellerRef.phone
  const address = (doc.statementType === 'sale' || doc.statementType === 'saleReturn')
    ? doc.buyerStockAddress
    : doc.sellerStockAddress
  const CID = (doc.statementType === 'sale' || doc.statementType === 'saleReturn')
    ? doc.buyerRef && doc.buyerRef.CID
    : doc.sellerRef && doc.sellerRef.CID
  const date = doc.date && moment(doc.date).format('YYYY/MM/DD')
  const payDate = doc.payDate && moment(doc.payDate).format('YYYY/MM/DD')
  return (
    <div className={classes['sheet-title-outer-div']}>
      <table className={classes['sheet-title']}>
        <tbody>
          <tr>
            <td className={classes['title-left']} />
            <td className={classes['title-right']}>
              <div data-name="廠商/維修單號">
                <div className={classes['title-right-agentKey']}>
                  {termDefine[doc.statementType].referTitle}:{doc.agentKey}
                </div>
              </div>
            </td>
            <td className={classes['page-num']}>
              第{pageNum}頁
            </td>
          </tr>
          <tr>
            <td className={classes['title-left']}>
              <div data-name="客戶名稱">
                <div className={classes['title-left-buyerName']}>
                  {termDefine[doc.statementType].recipientTitle}:{name}
                </div>
              </div>
            </td>
            <td className={classes['title-right']}>
              <div data-name="銷貨日期">
                <div className={classes['title-right-date']}>
                  {termDefine[doc.statementType].tableType}日期:{date}
                </div>
              </div>
            </td>
            <td />
          </tr>
          <tr>
            <td className={classes['title-left']}>
              <div data-name="客戶電話">
                <div className={classes['title-left-buyerPhone']}>
                  {termDefine[doc.statementType].recipientPhoneTitle}:{phone}
                </div>
              </div>
            </td>
            <td className={classes['title-right']}>
              <div data-name="結帳日期">
                <div className={classes['title-right-payDate']}>結帳日期:{payDate}</div>
              </div>
            </td>
            <td />
          </tr>
          <tr>
            <td className={classes['title-left']}>
              <div data-name="送貨地址">
                <div className={classes['title-left-buyerStockAddress']}>
                  {termDefine[doc.statementType].recipientAction}貨地址:{address}
                </div>
              </div>
            </td>
            <td className={classes['title-right']}>
              <div data-name="統一編號">
                <div className={classes['title-right-buyerCID']}>統一編號:{CID}</div>
              </div>
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  )
}

SheetTitle.propTypes = {
  doc: object.isRequired,
  classes: object.isRequired,
  pageNum: number.isRequired,
}

function ItemListHeader ({ doc, classes }) {
  return (
    <table className={classes['list-header']}>
      <tbody>
        <tr>
          <td data-name="項目" className={classes['items-rank']}>項目</td>
          <td data-name="條碼" className={classes['items-key']}>條碼</td>
          <td data-name="品名規格" className={classes['items-name']}>品名規格</td>
          <td data-name="數量" className={classes['items-qty']}>數量</td>
          <td data-name="單位" className={classes['items-unit']}>單位</td>
          <td data-name="售價" className={classes['items-realPrice']}>{termDefine[doc.statementType].priceName}</td>
          <td data-name="小計" className={classes['items-subtotal']}>小計</td>
          <td data-name="留白" className={classes['items-blank']} />
          <td data-name="備註" className={classes['items-note']}>備註</td>
        </tr>
      </tbody>
    </table>
  )
}

ItemListHeader.propTypes = {
  doc: object.isRequired,
  classes: object.isRequired,
}

function PageFooter ({ classes }) {
  return (
    <table className={classes['page-footer']}>
      <tbody>
        <tr>
          <td data-name="審核" className={classes['page-footer-col']}>審核:</td>
          <td data-name="經辦" className={classes['page-footer-col']}>經辦:</td>
          <td data-name="會計" className={classes['page-footer-col']}>會計:</td>
          <td data-name="業務" className={classes['page-footer-col']}>業務:</td>
          <td data-name="簽收" className={classes['page-footer-col']}>簽收:</td>
        </tr>
      </tbody>
    </table>
  )
}

PageFooter.propTypes = {
  classes: object.isRequired,
}

function PageHeader ({ doc, classes, pageNum }) {
  return (
    <table className={classes['page-header']}>
      <tbody>
        <tr className={classes['page-header-tr']}>
          <td data-name="單號" className={classes['page-header-key']}>{termDefine[doc.statementType].tableType}單號:{doc.key}</td>
          <td data-name="頁碼" className={classes['page-header-page-num']}>第{pageNum}頁</td>
        </tr>
        <tr className={classes['page-header-tr']}>
          <td data-name="單號" className={classes['page-header-key']} />
          <td data-name="頁碼" className={classes['page-header-page-num']} />
        </tr>
      </tbody>
    </table>
  )
}

PageHeader.propTypes = {
  doc: object.isRequired,
  classes: object.isRequired,
  pageNum: number.isRequired,
}

function StatementEnd ({ doc, classes }) {
  return (
    <div>
      <table className={classes['statement-end']}>
        <tbody>
          <tr className={classes['statement-end-tr']}>
            <td data-name="左" className={classes['statement-end-left']}>{doc.summary}</td>
            <td data-name="右標題" className={classes['statement-end-right-title']}>未稅合計:</td>
            <td data-name="右值" className={classes['statement-end-right-value']}>{numeral(10 * doc.amount).format('($0,0)')}</td>
          </tr>
          <tr className={classes['statement-end-tr']}>
            <td data-name="左" className={classes['statement-end-left']}>附註:{doc.note}</td>
            <td data-name="右標題" className={classes['statement-end-right-title']}>稅額:</td>
            <td data-name="右值" className={classes['statement-end-right-value']}>{numeral(10 * doc.tax).format('($0,0)')}</td>
          </tr>
          <tr className={classes['statement-end-tr']}>
            <td data-name="左" className={classes['statement-end-left']} />
            <td data-name="右標題" className={classes['statement-end-right-title']}>含稅合計:</td>
            <td data-name="右值" className={classes['statement-end-right-value']}>{numeral(10 * doc.afterTax).format('($0,0)')}</td>
          </tr>
          <tr className={classes['statement-end-tr']}>
            <td data-name="左" className={classes['statement-end-left']} />
            <td data-name="右標題" className={classes['statement-end-right-title']}>折讓金額:</td>
            <td data-name="右值" className={classes['statement-end-right-value']}>{numeral(10 * doc.discount).format('($0,0)')}</td>
          </tr>
        </tbody>
      </table>
      <table className={classes['statement-end']}>
        <tbody>
          <tr className={classes['statement-end-tr']}>
            <td data-name="左" className={classes['statement-end-left']} />
            <td data-name="右標題" className={classes['statement-end-right-title']}>應{termDefine[doc.statementType].accountAction}總計:</td>
            <td data-name="右值" className={classes['statement-end-right-value']}>{numeral(10 * doc.receivable).format('($0,0)')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

StatementEnd.propTypes = {
  doc: object.isRequired,
  classes: object.isRequired,
}

function EmptyLine ({ classes }) {
  return (
    <table>
      <tbody>
        <tr className={classes['empty-line']}>
          <td />
        </tr>
      </tbody>
    </table>
  )
}

EmptyLine.propTypes = {
  classes: object.isRequired,
}

function DocPrinter (props) {
  const { doc, classes } = props
  const LINE_LIMIT = 26 // 26
  const NEXT_PAGE_LINE_INIT = 6 // 6
  const STATEMENT_END_LINES = 5 // 5
  const CURRENT_LINE_NUM_INIT = 12 // 12
  const ONE_PAGE_ITEM_LIMIT = LINE_LIMIT - CURRENT_LINE_NUM_INIT - STATEMENT_END_LINES + 1 // 10
  const FIRST_PAGE_ITEM_LIMIT = LINE_LIMIT - CURRENT_LINE_NUM_INIT + 1 // 15
  const CONT_PAGE_ITEM_LIMIT = LINE_LIMIT - NEXT_PAGE_LINE_INIT + 1 // 21
  const FINAL_PAGE_ITEM_LIMIT = LINE_LIMIT - NEXT_PAGE_LINE_INIT - STATEMENT_END_LINES + 2 // 17
  const pageRecords = []
  let pageNum = 1
  let currentLineNum = CURRENT_LINE_NUM_INIT
  pageRecords[0] = {
    itemIndexes: [],
    emptyLineIndexes: [],
    pageNum,
  }

  // only generate print content while doc was loaded
  if (doc && doc.items) {
    doc.items.forEach((item, i) => {
      if (item) {
        if (currentLineNum > LINE_LIMIT) {
          currentLineNum = NEXT_PAGE_LINE_INIT
          pageNum += 1
          pageRecords.push({
            itemIndexes: [],
            emptyLineIndexes: [],
            pageNum,
          })
        }
        currentLineNum += 1
        pageRecords[pageNum - 1].itemIndexes.push(i)
      }
    })
    let pageMax = pageNum
    pageRecords.forEach((pageRecord) => {
      if (pageRecord.pageNum === 1 && pageMax === 1) {
        if (pageRecord.itemIndexes.length < ONE_PAGE_ITEM_LIMIT + 1) {
          let emptyIndex = 0
          while (emptyIndex < ONE_PAGE_ITEM_LIMIT - pageRecord.itemIndexes.length) {
            pageRecord.emptyLineIndexes.push(emptyIndex)
            emptyIndex += 1
          }
        } else if (pageRecord.itemIndexes.length < FIRST_PAGE_ITEM_LIMIT + 1) {
          let emptyIndex = 0
          while (emptyIndex < FIRST_PAGE_ITEM_LIMIT - pageRecord.itemIndexes.length) {
            pageRecord.emptyLineIndexes.push(emptyIndex)
            emptyIndex += 1
          }
          const nextPageEmptyLineIndexes = []
          emptyIndex = 0
          while (emptyIndex < FINAL_PAGE_ITEM_LIMIT) {
            nextPageEmptyLineIndexes.push(emptyIndex)
            emptyIndex += 1
          }
          pageRecords.push({
            itemIndexes: [],
            emptyLineIndexes: nextPageEmptyLineIndexes,
            pageNum: 2,
          })
          pageMax = 2
        }
      } else if (pageRecord.pageNum === pageMax) {
        if (pageRecord.itemIndexes.length < FINAL_PAGE_ITEM_LIMIT + 1) {
          let emptyIndex = 0
          while (emptyIndex < FINAL_PAGE_ITEM_LIMIT - pageRecord.itemIndexes.length) {
            pageRecord.emptyLineIndexes.push(emptyIndex)
            emptyIndex += 1
          }
        } else if (pageRecord.itemIndexes.length < CONT_PAGE_ITEM_LIMIT + 1) {
          let emptyIndex = 0
          while (emptyIndex < CONT_PAGE_ITEM_LIMIT - pageRecord.itemIndexes.length) {
            pageRecord.emptyLineIndexes.push(emptyIndex)
            emptyIndex += 1
          }
          const nextPageEmptyLineIndexes = []
          emptyIndex = 0
          while (emptyIndex < FINAL_PAGE_ITEM_LIMIT) {
            nextPageEmptyLineIndexes.push(emptyIndex)
            emptyIndex += 1
          }
          pageRecords.push({
            itemIndexes: [],
            emptyLineIndexes: nextPageEmptyLineIndexes,
            pageNum: pageMax + 1,
          })
          pageMax += 1
        }
      }
    })
    return (
      <div className={classes.print}>
        {pageRecords && pageRecords.map((page) => {
          if (page.pageNum === 1 && pageMax !== 1) {
            return (
              <div key={`page${page.pageNum}`}>
                <div className={classes.statement1}>
                  <StatementHeader {...{ doc, classes }} />
                  <SheetTitle {...{ doc, classes, pageNum: 1 }} />
                  <div className={classes['items-outer-div']}>
                    <ItemListHeader {...{ doc, classes }} />
                    {page.itemIndexes && page.itemIndexes.map(i => (
                      <div key={`up${i}`}>
                        <table className={classes.items}>
                          <tbody>
                            <tr className={classes['items-td']}>
                              <td data-name="項目" className={classes['items-rank']}>{i + 1}</td>
                              <td data-name="條碼" className={classes['items-key']}>{doc.items[i].key}</td>
                              <td data-name="品名規格" className={classes['items-name']}>{doc.items[i].name}</td>
                              <td data-name="數量" className={classes['items-qty']}>{doc.items[i].qty}</td>
                              <td data-name="單位" className={classes['items-unit']}>{doc.items[i].unit}</td>
                              <td data-name="售價" className={classes['items-realPrice']}>{10 * doc.items[i].price}</td>
                              <td data-name="小計" className={classes['items-subtotal']}>{10 * doc.items[i].subtotal}</td>
                              <td data-name="留白" className={classes['items-blank']} />
                              <td data-name="備註" className={classes['items-note']}>{doc.items[i].note}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                    {page.emptyLineIndexes && page.emptyLineIndexes.map(i => (
                      <div key={`upEmpty${i}`}>
                        <EmptyLine {...{ classes }} />
                      </div>
                    ))}
                  </div>
                  <PageFooter {...{ classes }} />
                </div>
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <div className={classes.statement2}>
                  <StatementHeader {...{ doc, classes }} />
                  <SheetTitle {...{ doc, classes, pageNum: 1 }} />
                  <div className={classes['items-outer-div']}>
                    <ItemListHeader {...{ doc, classes }} />
                    {page.itemIndexes && page.itemIndexes.map(i => (
                      <div key={`lower${i}`}>
                        <table className={classes.items}>
                          <tbody>
                            <tr className={classes['items-td']}>
                              <td data-name="項目" className={classes['items-rank']}>{i + 1}</td>
                              <td data-name="條碼" className={classes['items-key']}>{doc.items[i].key}</td>
                              <td data-name="品名規格" className={classes['items-name']}>{doc.items[i].name}</td>
                              <td data-name="數量" className={classes['items-qty']}>{doc.items[i].qty}</td>
                              <td data-name="單位" className={classes['items-unit']}>{doc.items[i].unit}</td>
                              <td data-name="售價" className={classes['items-realPrice']}>{10 * doc.items[i].price}</td>
                              <td data-name="小計" className={classes['items-subtotal']}>{10 * doc.items[i].subtotal}</td>
                              <td data-name="留白" className={classes['items-blank']} />
                              <td data-name="備註" className={classes['items-note']}>{doc.items[i].note}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                    {page.emptyLineIndexes && page.emptyLineIndexes.map(i => (
                      <div key={`upEmpty${i}`}>
                        <EmptyLine {...{ classes }} />
                      </div>
                    ))}
                  </div>
                  <PageFooter {...{ classes }} />
                  <div className={classes['page-break']} />
                </div>
              </div>
            )
          }
          if (page.pageNum === 1 && pageMax === 1) {
            return (
              <div key={`page${page.pageNum}`}>
                <div className={classes.statement1}>
                  <StatementHeader {...{ doc, classes }} />
                  <SheetTitle {...{ doc, classes, pageNum: 1 }} />
                  <div className={classes['items-outer-div']}>
                    <ItemListHeader {...{ doc, classes }} />
                    {page.itemIndexes && page.itemIndexes.map(i => (
                      <div key={`up${i}`}>
                        <table className={classes.items}>
                          <tbody>
                            <tr className={classes['items-td']}>
                              <td data-name="項目" className={classes['items-rank']}>{i + 1}</td>
                              <td data-name="條碼" className={classes['items-key']}>{doc.items[i].key}</td>
                              <td data-name="品名規格" className={classes['items-name']}>{doc.items[i].name}</td>
                              <td data-name="數量" className={classes['items-qty']}>{doc.items[i].qty}</td>
                              <td data-name="單位" className={classes['items-unit']}>{doc.items[i].unit}</td>
                              <td data-name="售價" className={classes['items-realPrice']}>{10 * doc.items[i].price}</td>
                              <td data-name="小計" className={classes['items-subtotal']}>{10 * doc.items[i].subtotal}</td>
                              <td data-name="留白" className={classes['items-blank']} />
                              <td data-name="備註" className={classes['items-note']}>{doc.items[i].note}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                  <StatementEnd {...{ doc, classes }} />
                  {page.emptyLineIndexes && page.emptyLineIndexes.map(i => (
                    <div key={`upEmpty${i}`}>
                      <EmptyLine {...{ classes }} />
                    </div>
                  ))}
                  <PageFooter {...{ classes }} />
                </div>
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <div className={classes.statement2}>
                  <StatementHeader {...{ doc, classes }} />
                  <SheetTitle {...{ doc, classes, pageNum: 1 }} />
                  <div className={classes['items-outer-div']}>
                    <ItemListHeader {...{ doc, classes }} />
                    {page.itemIndexes && page.itemIndexes.map(i => (
                      <div key={`lower${i}`}>
                        <table className={classes.items}>
                          <tbody>
                            <tr className={classes['items-td']}>
                              <td data-name="項目" className={classes['items-rank']}>{i + 1}</td>
                              <td data-name="條碼" className={classes['items-key']}>{doc.items[i].key}</td>
                              <td data-name="品名規格" className={classes['items-name']}>{doc.items[i].name}</td>
                              <td data-name="數量" className={classes['items-qty']}>{doc.items[i].qty}</td>
                              <td data-name="單位" className={classes['items-unit']}>{doc.items[i].unit}</td>
                              <td data-name="售價" className={classes['items-realPrice']}>{10 * doc.items[i].price}</td>
                              <td data-name="小計" className={classes['items-subtotal']}>{10 * doc.items[i].subtotal}</td>
                              <td data-name="留白" className={classes['items-blank']} />
                              <td data-name="備註" className={classes['items-note']}>{doc.items[i].note}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                  <StatementEnd {...{ doc, classes }} />
                  {page.emptyLineIndexes && page.emptyLineIndexes.map(i => (
                    <div key={`upEmpty${i}`}>
                      <EmptyLine {...{ classes }} />
                    </div>
                  ))}
                  <PageFooter {...{ classes }} />
                  <div className={classes['page-break']} />
                </div>
              </div>
            )
          }
          if (page.pageNum !== pageMax) {
            return (
              <div key={`page${page.pageNum}`}>
                <div className={classes.statement1}>
                  <PageHeader {...{ doc, classes, pageNum: page.pageNum }} />
                  <div className={classes['items-outer-div']}>
                    <ItemListHeader {...{ doc, classes }} />
                    {page.itemIndexes && page.itemIndexes.map(i => (
                      <div key={`up${i}`}>
                        <table className={classes.items}>
                          <tbody>
                            <tr className={classes['items-td']}>
                              <td data-name="項目" className={classes['items-rank']}>{i + 1}</td>
                              <td data-name="條碼" className={classes['items-key']}>{doc.items[i].key}</td>
                              <td data-name="品名規格" className={classes['items-name']}>{doc.items[i].name}</td>
                              <td data-name="數量" className={classes['items-qty']}>{doc.items[i].qty}</td>
                              <td data-name="單位" className={classes['items-unit']}>{doc.items[i].unit}</td>
                              <td data-name="售價" className={classes['items-realPrice']}>{10 * doc.items[i].price}</td>
                              <td data-name="小計" className={classes['items-subtotal']}>{10 * doc.items[i].subtotal}</td>
                              <td data-name="留白" className={classes['items-blank']} />
                              <td data-name="備註" className={classes['items-note']}>{doc.items[i].note}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                  {page.emptyLineIndexes && page.emptyLineIndexes.map(i => (
                    <div key={`upEmpty${i}`}>
                      <EmptyLine {...{ classes }} />
                    </div>
                  ))}
                  <PageFooter {...{ classes }} />
                </div>
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <EmptyLine {...{ classes }} />
                <div className={classes.statement2}>
                  <PageHeader {...{ doc, classes, pageNum: page.pageNum }} />
                  <div className={classes['items-outer-div']}>
                    <ItemListHeader {...{ doc, classes }} />
                    {page.itemIndexes && page.itemIndexes.map(i => (
                      <div key={`lower${i}`}>
                        <table className={classes.items}>
                          <tbody>
                            <tr className={classes['items-td']}>
                              <td data-name="項目" className={classes['items-rank']}>{i + 1}</td>
                              <td data-name="條碼" className={classes['items-key']}>{doc.items[i].key}</td>
                              <td data-name="品名規格" className={classes['items-name']}>{doc.items[i].name}</td>
                              <td data-name="數量" className={classes['items-qty']}>{doc.items[i].qty}</td>
                              <td data-name="單位" className={classes['items-unit']}>{doc.items[i].unit}</td>
                              <td data-name="售價" className={classes['items-realPrice']}>{10 * doc.items[i].price}</td>
                              <td data-name="小計" className={classes['items-subtotal']}>{10 * doc.items[i].subtotal}</td>
                              <td data-name="留白" className={classes['items-blank']} />
                              <td data-name="備註" className={classes['items-note']}>{doc.items[i].note}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                    {page.emptyLineIndexes && page.emptyLineIndexes.map(i => (
                      <div key={`upEmpty${i}`}>
                        <EmptyLine {...{ classes }} />
                      </div>
                    ))}
                    <PageFooter {...{ classes }} />
                    <div className={classes['page-break']} />
                  </div>
                </div>
              </div>
            )
          }
          return (
            <div key={`page${page.pageNum}`}>
              <div className={classes.statement1}>
                <PageHeader {...{ doc, classes, pageNum: page.pageNum }} />
                <div className={classes['items-outer-div']}>
                  <ItemListHeader {...{ doc, classes }} />
                  {page.itemIndexes && page.itemIndexes.map(i => (
                    <div key={`up${i}`}>
                      <table className={classes.items}>
                        <tbody>
                          <tr className={classes['items-td']}>
                            <td data-name="項目" className={classes['items-rank']}>{i + 1}</td>
                            <td data-name="條碼" className={classes['items-key']}>{doc.items[i].key}</td>
                            <td data-name="品名規格" className={classes['items-name']}>{doc.items[i].name}</td>
                            <td data-name="數量" className={classes['items-qty']}>{doc.items[i].qty}</td>
                            <td data-name="單位" className={classes['items-unit']}>{doc.items[i].unit}</td>
                            <td data-name="售價" className={classes['items-realPrice']}>{10 * doc.items[i].price}</td>
                            <td data-name="小計" className={classes['items-subtotal']}>{10 * doc.items[i].subtotal}</td>
                            <td data-name="留白" className={classes['items-blank']} />
                            <td data-name="備註" className={classes['items-note']}>{doc.items[i].note}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
                <StatementEnd {...{ doc, classes }} />
                {page.emptyLineIndexes && page.emptyLineIndexes.map(i => (
                  <div key={`upEmpty${i}`}>
                    <EmptyLine {...{ classes }} />
                  </div>
                ))}
                <PageFooter {...{ classes }} />
              </div>
              <EmptyLine {...{ classes }} />
              <EmptyLine {...{ classes }} />
              <EmptyLine {...{ classes }} />
              <EmptyLine {...{ classes }} />
              <EmptyLine {...{ classes }} />
              <div className={classes.statement2}>
                <PageHeader {...{ doc, classes, pageNum: page.pageNum }} />
                <div className={classes['items-outer-div']}>
                  <ItemListHeader {...{ doc, classes }} />
                  {page.itemIndexes && page.itemIndexes.map(i => (
                    <div key={`lower${i}`}>
                      <table className={classes.items}>
                        <tbody>
                          <tr className={classes['items-td']}>
                            <td data-name="項目" className={classes['items-rank']}>{i + 1}</td>
                            <td data-name="條碼" className={classes['items-key']}>{doc.items[i].key}</td>
                            <td data-name="品名規格" className={classes['items-name']}>{doc.items[i].name}</td>
                            <td data-name="數量" className={classes['items-qty']}>{doc.items[i].qty}</td>
                            <td data-name="單位" className={classes['items-unit']}>{doc.items[i].unit}</td>
                            <td data-name="售價" className={classes['items-realPrice']}>{10 * doc.items[i].price}</td>
                            <td data-name="小計" className={classes['items-subtotal']}>{10 * doc.items[i].subtotal}</td>
                            <td data-name="留白" className={classes['items-blank']} />
                            <td data-name="備註" className={classes['items-note']}>{doc.items[i].note}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                  <StatementEnd {...{ doc, classes }} />
                  {page.emptyLineIndexes && page.emptyLineIndexes.map(i => (
                    <div key={`upEmpty${i}`}>
                      <EmptyLine {...{ classes }} />
                    </div>
                  ))}
                  <PageFooter {...{ classes }} />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  // generate blank while sale item was empty
  return (
    <div className={classes.div} />
  )
}

DocPrinter.propTypes = {
  doc: object,
  classes: object.isRequired,
}

DocPrinter.defaultProps = {
  doc: { },
}

export default withStyles(styles)(DocPrinter)
