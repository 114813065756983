const model = {
  title: '銷售資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        ragicField: 1000550,
        label: '銷售編號',
        type: 'String',
        unique: true,
      },
      term: {
        ragicField: 1000551,
        label: '銷售項目',
        type: 'String',
        input: 'select',
        options: ['眼鏡', '眼鏡附件', '眼鏡折讓', '眼鏡附件折讓'],
      },
      product: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', lazyFetch: true, label: '商品條碼', reference: 'products', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '商品條碼', ragicField: 1000064 },
        },
      },
      productType: {
        ragicField: 1000714,
        label: '商品類別',
        type: 'String',
        input: 'select',
        options: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      },
      name: {
        ragicField: 1000553,
        label: '商品名稱',
        type: 'String',
      },
      fixedPrice: {
        ragicField: 1000554,
        label: '定價',
        type: 'Number',
      },
      price: {
        ragicField: 1000556,
        label: '售價',
        type: 'Number',
      },
      qty: {
        ragicField: 1000598,
        label: '數量',
        type: 'Number',
      },
      subtotal: {
        ragicField: 1000555,
        label: '小計',
        type: 'Number',
      },
      refundQty: {
        ragicField: 1000972,
        label: '已退數量',
        type: 'Number',
      },
      refundAmount: {
        ragicField: 1000970,
        label: '已退金額',
        type: 'Number',
      },
      refundStatus: {
        ragicField: 1000969,
        label: '銷退',
        type: 'String',
        input: 'select',
        options: ['是', '否'],
      },
      discountAmortization: {
        ragicField: 1001029,
        label: '攤提折讓',
        type: 'Number',
      },
      retailOrder: {
        type: 'Object',
        contents: {
          id: { type: 'ObjectId', label: '承件單號', reference: 'retailOrders', refKeys: ['key'], view: 'form' },
          key: { type: 'String', label: '承件單號', ragicField: 1000522 },
        },
      },
      updatedAt: {
        ragicField: 1001040,
        label: '更新時間',
        type: 'Date',
        format: 'YYYY-MM-DD hh:mm:ss',
      },
    },
  },
  index: {
    retailOrderId: {
      key: {
        'retailOrder.id': 1,
      },
    },
    updatedAt: {
      key: {
        updatedAt: 1,
      },
    },
  },
}

const main = {
  title: '銷售資料管理',
  list: {
    ui: {
      fields: [
        'key',
        'term',
        'product.key',
        'productType',
        'name',
        'fixedPrice',
        'price',
        'qty',
        'subtotal',
        // 'orderKeyRef',
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key'],
          ['term'],
          ['product.id'],
          ['productType'],
          ['name'],
          ['fixedPrice'],
          ['price'],
          ['qty'],
          ['subtotal'],
          // ['orderKeyRef'],
        ],
      },
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main,
  },
}
