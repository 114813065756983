module.exports = [
  {
    category: '基本資料',
    features: ['staffers:main', 'suppliers', 'stores:main', 'parties'],
  },
  {
    category: '商品資料管理',
    features: [
      'products:typeA',
      'products:typeB',
      'products:typeC',
      'products:typeD',
      'products:typeE',
      'products:typeF',
      'products:typeG',
      'products:suppliers',
      'typeAColors',
      'lensTypes',
      'brands',
      'serieses',
      'products',
      'products:stocks',
      'products:storeStocks',
      'stocks:records',
      'stockRecords',
      'itemBarcodes:main',
      'toRagicSyncer',
    ],
  },
  {
    category: '進銷表單',
    features: [
      'storePurchases:main',
      'storePurchaseItems:main',
      'storePurchases:centerStaffers',
      'storePurchaseItems:centerStaffers',
      'storePurchases:suppliers',
      'storePurchaseItems:suppliers',
      'storePurchaseReturns:main',
      'storePurchaseReturnItems:main',
      'storePurchaseReturns:centerStaffers',
      'storePurchaseReturnItems:centerStaffers',
      'storePurchaseReturns:suppliers',
      'storePurchaseReturnItems:suppliers',
      'purchases:main',
      'purchaseReturns:main',
      'purchaseItems',
      'purchaseReturnItems',
      'storePurchaseItems',
      'storePurchaseReturnItems',
      // 'fetchRagicCenter',
    ],
  },
  {
    category: '售服用表單',
    features: [
      'sales:main',
      'saleReturns:main',
      'saleItems',
      'saleReturnItems',
    ],
  },
  {
    category: '帳務管理',
    features: [
      'recStatements:main',
      'payStatements:main',
      'storeStatements:main',
      'payments',
    ],
  },
  {
    category: '銷售資料',
    features: ['cooper202101Records'],
  },
  {
    category: 'Entries Forms',
    features: ['accounts', 'paymentMethods', 'entries'],
  },
  {
    category: 'Upload',
    features: ['uploadTemplate'],
  },
  {
    category: '驗證中',
    features: [
      'stockManagementAdmin',
      'centerStockManagement',
      'storeStockManagement',
      'centerAssertManagement',
      'storeAssertManagement',
      'ragicSyncer',
    ],
  },
  {
    category: '門市營運管理',
    features: [
      'customers:main',
      'prescripts:main',
      'retailOrders:main',
      'retailSales:main',
      'creditCards:main',
      'miscellaneous:main',
      'retailSaleReturns:main',
      'customers:retailOrders',
      'retailOrders:prescripts',
      'retailOrders:retailSales',
      'retailOrders:invoices',
      'retailOrders:retailSaleReturns',
      'retailOrders:creditCards',
      'taiwanpays:main',
    ],
  },
  {
    category: '發票資料管理',
    features: [
      'machines:main',
      'packs:main',
      'invoices:main',
      'invoiceDailyReports:main',
    ],
  },
  {
    category: '報表資料管理',
    features: [
      'dailyReports:main',
    ],
  },
]
